var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-fp-video-table"},[_c('filter-panel',{on:{"clear":_vm.clearFilter,"filter":_vm.filterData},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"row p-2"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Floor Plan Name","name":"filterprojectUnitFloorPlan","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData()}},model:{value:(_vm.filter['projectUnitFloorPlan:name']),callback:function ($$v) {_vm.$set(_vm.filter, 'projectUnitFloorPlan:name', $$v)},expression:"filter['projectUnitFloorPlan:name']"}})],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.videoTableColumns,"rows":_vm.videoTableData,"totalRows":_vm.videoTablePagination.total,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: _vm.perPageOptions,
        dropdownAllowAll: false
      },"sort-options":{
        enabled: false
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.openVideoDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[(_vm.status == 'Pending Approval')?_c('button',{staticClass:"btn main",on:{"click":function($event){return _vm.openEditModal(props.row)}}},[_c('i',{staticClass:"fas fa-pen"})]):_vm._e(),_c('button',{staticClass:"btn danger bordered ml-1",on:{"click":function($event){return _vm.deleteVideo(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(props.column.field == 'video')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('a',{attrs:{"href":props.row.videoLink}},[_vm._v(_vm._s(props.row.videoLink))]),(_vm.isPreviewActive)?_c('video-embed',{attrs:{"css":"mt-1 embed-responsive-16by9","src":props.row.videoLink}}):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn toggle",class:{ active: _vm.isPreviewActive },on:{"click":function($event){_vm.isPreviewActive = !_vm.isPreviewActive}}},[_c('i',{staticClass:"fas fa-film"}),_c('span',{staticClass:"d-none sm-d-inline-block ml-1"},[_vm._v(" Video Preview")])]),_c('button',{staticClass:"btn toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])])])],1),_c('modal',{model:{value:(_vm.isEditModalShown),callback:function ($$v) {_vm.isEditModalShown=$$v},expression:"isEditModalShown"}},[_c('edit-video',{staticClass:"card",attrs:{"video":_vm.videoToEdit},on:{"cancel":function($event){_vm.isEditModalShown = false},"submit":_vm.updateVideo}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }